import "../scss/main.scss";

jQuery(function ($) {
  if ($(window).width() > 992) {
    $("header.dark img.custom-logo").attr(
      "src",
      "http://jastrzebia.salmodesign.pl/wp-content/uploads/2021/10/logo.png"
    );
  }

  $(".toggle-nav").click(function (e) {
    $(".navbar .mobile-menu-container").slideToggle();
    $(".icon-bar").toggleClass("toggled");
    e.preventDefault();
  });

  $("button.pswp__button.pswp__button--arrow--left").attr(
    "title",
    "Poprzednie zdjęcie"
  );
  $("button.pswp__button.pswp__button--arrow--right").attr(
    "title",
    "Następne zdjęcie"
  );

  $(".flats").owlCarousel({
    loop: true,
    nav: true,
    margin: 40,
    navText: [
      '<img src="https://jastrzebia.salmodesign.pl/wp-content/themes/ventis/img/arrow_n.svg" />',
      '<img src="https://jastrzebia.salmodesign.pl/wp-content/themes/ventis/img/arrow_n.svg" />',
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1280: {
        items: 3,
      },
    },
  });

  $(".images-slider").owlCarousel({
    loop: true,
    nav: true,
    margin: 10,
    navText: [
      '<img src="https://jastrzebia.salmodesign.pl/wp-content/themes/ventis/img/arrow_n.svg" />',
      '<img src="https://jastrzebia.salmodesign.pl/wp-content/themes/ventis/img/arrow_n.svg" />',
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1280: {
        items: 3,
      },
    },
  });

  $('ul.slides').owlCarousel({
    items: 1,
    nav: true,
    navText: [
      '<img src="https://jastrzebia.salmodesign.pl/wp-content/themes/ventis/img/arrow_n.svg" />',
      '<img src="https://jastrzebia.salmodesign.pl/wp-content/themes/ventis/img/arrow_n.svg" />',
    ],
  });

  $("#tabs").tabs();

  $(".the_flat .overlay").click(function () {
    $(".the_flat").removeClass("active");
    $('#floors_section_2 .flat').fadeOut();
    $('.flat-info-box').hide();
    $(this).parent().addClass("active");

    var id = $(this).parent().attr('id');
    $('#floors_section_2 #apartament-' + id).fadeIn();
    $('#floors_section_2 #apartament-' + id + '-info').fadeIn();
    $('.page-template-page-garaz #floors_section_2 #garaz-' + id + '-info').fadeIn();
    $( 'html, body' ).animate( { scrollTop: $('#floors_section_2').offset().top + 250},1500 );
  });

  $('#nav0').mouseover(function(){
    $('#floors #parter').addClass('hovered');
  })

  $('#nav1').mouseover(function(){
    $('#floors #pierwsze').addClass('hovered');
  })  
  
  $('#nav2').mouseover(function(){
    $('#floors #drugie').addClass('hovered');
  })

  $('#nav3').mouseover(function(){
    $('#floors #trzecie').addClass('hovered');
  })

  $('#nav4').mouseover(function(){
    $('#floors #czwarte').addClass('hovered');
  })

  $('#apartamenty_section_1 ul.floor_nav li a').mouseout(function(){
    $('#floors .hover').removeClass('hovered');
  })
});
